var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.suppliers,
      "loading": _vm.state.isLoadingSuppliers,
      "with-notes-and-tasks": "",
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalSuppliers
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onClickEdit,
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      },
      "click:tasks": function clickTasks($event) {
        return _vm.listeners.onOpenTasksDialog($event);
      },
      "click:notes": function clickNotes($event) {
        return _vm.listeners.onOpenNotesDialog($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "text": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onToggleFilterDropdown();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _vm.functions.hasSufficientRights(_vm.constants.Rights.SUPPLIER_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditSupplierDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('contractData.suppliers.actions.addSupplier')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('SuppliersFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.SUPPLIER_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.supplierTasks,
      "entity-name": "supplier",
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.supplierNotes,
      "entity-name": "supplier",
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeSupplier,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "translation-key": _vm.$route.meta.i18nKey
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditSupplierDialogOpen ? _c('AddEditSupplierDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "supplier-to-edit": _vm.state.activeSupplier
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditSupplierDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditSupplierDialogOpen", $$v);
      },
      expression: "state.isAddEditSupplierDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteSupplierDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteSupplier();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteSupplierDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteSupplierDialogOpen", $$v);
      },
      expression: "state.isDeleteSupplierDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contractData.suppliers.dialog.delete.text', {
    name: _vm.state.activeSupplier.name
  })) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }