import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetCorporateUnits,
  UseGetCorporateUnitsBasic,
  UseCreateCorporateUnit,
  UseDeleteCorporateUnit,
  UseUpdateCorporateUnit,
  CorporateUnitOutput,
  CorporateUnitInput,
  CorporateUnitId,
} from '@/api/types/corporateUnit'
import { BasicEntity } from '@/api/types/misc'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'corporate-units'

function useGetCorporateUnits<T>(): UseGetCorporateUnits<T> {
  const axios = useAxiosPaginated<T>({ method: 'GET', url: `/${VERSION}/${RESOURCE}`, params: { size: 9999 } })
  return axios
}

function useGetCorporateUnitsBasic(): UseGetCorporateUnitsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateCorporateUnit(): UseCreateCorporateUnit {
  const axios = useAxios<PromiseType<ReturnType<UseCreateCorporateUnit['createCorporateUnit']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createCorporateUnit(data: CorporateUnitInput): Promise<CorporateUnitOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createCorporateUnit,
  }
}

function useUpdateCorporateUnit(): UseUpdateCorporateUnit {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateCorporateUnit['updateCorporateUnit']>>>({ method: 'PUT' })

  function updateCorporateUnit(id: CorporateUnitId, data: CorporateUnitOutput): Promise<CorporateUnitOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateCorporateUnit,
  }
}

function useDeleteCorporateUnit(): UseDeleteCorporateUnit {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteCorporateUnit['deleteCorporateUnit']>>>({ method: 'DELETE' })

  function deleteCorporateUnit(id: CorporateUnitId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteCorporateUnit,
  }
}

export {
  useGetCorporateUnits,
  useGetCorporateUnitsBasic,
  useCreateCorporateUnit,
  useUpdateCorporateUnit,
  useDeleteCorporateUnit,
}
