
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import {
  mdiPlus,
  mdiNotebookEditOutline,
  mdiTextBoxCheckOutline,
  mdiPencil,
  mdiDelete,
  mdiFileExport,
  mdiFilter,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useFilter } from '@/composables/useFilter'
import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'

import { useDeleteSupplier, useExportSuppliers, useGetSuppliers, useGetSuppliersBasic } from '@/api/supplier'
import { useGetCorporateUnitsBasic } from '@/api/corporateUnit'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { SupplierOutput } from '@/api/types/supplier'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/contractData/views/suppliers/types'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Suppliers',
  components: {
    CommonTableView,
    AddEditSupplierDialog: () => import('@/views/contractData/views/suppliers/components/AddEditSupplierDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    SuppliersFilterBar: () => import('@/views/contractData/views/suppliers/components/SuppliersFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('contractData.suppliers.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.name'),
        value: 'name',
        sortable: true,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.phone1'),
        value: 'phone1',
        sortable: true,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.emailCorporate'),
        value: 'emailCorporate',
        sortable: true,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.applicationsReceived'),
        value: 'applicationsReceived',
        sortable: false,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.applicantsCommissioned'),
        value: 'applicantsCommissioned',
        sortable: false,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.currentlyActiveContracts'),
        value: 'currentlyActiveContracts',
        sortable: false,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.suppliers.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof SupplierOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'phone2',
      'address1',
      'address2',
      'addressZipCode',
      'addressCity',
      'addressCountry',
      'emailCorporate',
    ]

    const isAddEditSupplierDialogOpen = ref(false)

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditSupplierDialogOpen.value = false

      isDeleteSupplierDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeSupplier.value = null
    }

    const isEditMode = ref(false)

    const activeSupplier = ref<SupplierOutput | null>(null)

    function onClickEdit(_, { item: supplier }: { item: SupplierOutput }): void {
      if (hasSufficientRights(Rights.SUPPLIER_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditSupplierDialogOpen.value = true

        activeSupplier.value = { ...supplier }
      }
    }

    const isDeleteSupplierDialogOpen = ref(false)

    function onClickDelete(supplier: SupplierOutput): void {
      activeSupplier.value = { ...supplier }

      isDeleteSupplierDialogOpen.value = true
    }

    const { deleteSupplier } = useDeleteSupplier()

    async function onDeleteSupplier(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteSupplier(activeSupplier.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteSupplierDialogOpen.value = false

      activeSupplier.value = null

      init()
    }

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(supplier: SupplierOutput): void {
      activeSupplier.value = { ...supplier }

      isRowInfoDialogOpen.value = true
    }

    const {
      exec: getCorporateUnitsBasic,
      data: corporateUnitsBasic,
      isLoading: isLoadingCorporateUnitsBasic,
    } = useGetCorporateUnitsBasic()

    const {
      exec: getSuppliersBasic,
      data: suppliersBasic,
      isLoading: isLoadingSuppliersBasic,
    } = useGetSuppliersBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!corporateUnitsBasic.value?.length || !suppliersBasic.value?.length) {
          getCorporateUnitsBasic()
          getSuppliersBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('contractData.suppliers.filters.supplierIds'),
        items: computed(() => suppliersBasic.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('contractData.suppliers.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.CorporateUnitIds,
        value: null,
        label: root.$t('contractData.suppliers.filters.corporateUnit'),
        items: computed(() => corporateUnitsBasic.value ?? []),
        isLoading: computed(() => isLoadingCorporateUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.AddressCountry,
        value: null,
        label: root.$t('contractData.suppliers.filters.addressCountry'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'name', desc: false })

    const {
      exec: getSuppliers,
      data: suppliers,
      isLoading: isLoadingSuppliers,
      paginationResponse,
    } = useGetSuppliers()

    const totalSuppliers = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getSuppliers({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportSuppliers } = useExportSuppliers()

    async function onExportSuppliers() {
      try {
        await exportSuppliers()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: supplierNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<SupplierOutput>(suppliers, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: supplierTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<SupplierOutput>(suppliers, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiPlus,
        mdiNotebookEditOutline,
        mdiTextBoxCheckOutline,
        mdiPencil,
        mdiDelete,
        mdiFileExport,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        isAddEditSupplierDialogOpen,

        suppliers,
        isLoadingSuppliers,

        isEditMode,
        activeSupplier,

        isDeleteSupplierDialogOpen,

        isRowInfoDialogOpen,

        isTasksDialogOpen,
        supplierTasks,
        tasksEntityId,

        isNotesDialogOpen,
        supplierNotes,
        notesEntityId,

        vuetifyTableOptions,
        paginationParams,
        totalSuppliers,

        isFilterDropdownOpen,
      },
      listeners: {
        onCloseAddEditDeleteInfoDialog,

        onClickEdit,

        onClickDelete,

        onClickInfo,

        onDeleteSupplier,

        onExportSuppliers,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onToggleFilterDropdown,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
